import paper from 'paper';

const LINEDASH = [5, 5];

const toolLines = {
  data() {
    return {
      lines: {
        path: null,
        dashed: false,
      },
    };
  },
  mounted() {
    this.onTool('rivers', this.enableLineTool);
    this.onTool('roads', () => {
      this.enableLineTool(true);
    });
  },
  methods: {
    enableLineTool(dashed = false) {
      this.activateLayer('lines');

      this.lines.dashed = dashed;
      this.tool.minDistance = 0;

      this.tool.onMouseDown = this.lineToolStartPath;
      this.tool.onMouseDrag = this.lineToolAddPoint;
      this.tool.onMouseUp = this.lineToolEndPath;
    },
    lineToolStartPath(event) {
      this.path = new paper.Path({
        strokeColor: this.strokeColor,
        dashArray: this.lines.dashed ? LINEDASH : [],
      });
      this.lineToolAddPoint(event);
    },
    lineToolAddPoint(event) {
      this.path.add(event.point);
    },
    lineToolEndPath() {
      // Simplify the path a bit to smooth it
      this.path.simplify(5);
      // Clip the path to only include the intersection with land
      const clippedPath = this.path.intersect(this.terrainGetShape(), { trace: false });
      // Remove the "preview path"
      this.path.remove();

      this.historyAdd(
        this.linesUndo(clippedPath),
        this.linesRedo(clippedPath),
      );
    },
    linesUndo(path) {
      return () => {
        path.remove();
      };
    },
    linesRedo(path) {
      return () => {
        this.layers.lines.addChild(path);
      };
    },
  },
};

export default toolLines;
