import { Node, mergeAttributes } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import Suggestion from '@tiptap/suggestion';
import { PluginKey } from 'prosemirror-state';
import CutoutComponent from '../CutoutComponent.vue';

export const CutoutPluginKey = new PluginKey('cutout');

export default Node.create({
  name: 'cutout',

  group: 'block',

  atom: true,

  defaultOptions: {
    HTMLAttributes: {},
    suggestion: {
      char: '#',
      pluginKey: CutoutPluginKey,
      allowSpaces: false,
      command: ({ editor, range, props }) => {
        editor
          .chain()
          .focus()
          .insertContentAt(range, [
            {
              type: 'cutout',
              attrs: props,
            },
            {
              type: 'text',
              text: ' ',
            },
          ])
          .run();
      },
      allow: ({ editor, range }) => editor.can().insertContentAt(range, { type: 'cutout' }),
    },
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }

          return {
            'data-id': attributes.id,
          };
        },
      },
      part: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-part'),
        renderHTML: (attributes) => {
          if (!attributes.part) {
            return {};
          }

          return {
            'data-part': attributes.part,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'cutout',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['cutout', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(CutoutComponent);
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});
