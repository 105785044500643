<template>
  <div class="category-selector">
    <DropdownButton v-if="currentCategory">
      <template #trigger>
        <p class="label">
          <span class="category-icon material-icons-outlined">{{ currentCategory.icon }}</span>
          {{ currentCategory.label }}
        </p>
      </template>

      <template #default="{close}">
        <ul>
          <li
            v-for="category in categories"
            :key="category.key"
            @click="$emit('update', category); close()"
            class="item"
          >
            <span class="category-icon material-icons-outlined">{{ category.icon }}</span>
            {{ category.label }}
          </li>
        </ul>
      </template>
    </DropdownButton>
  </div>
</template>

<script>
import DropdownButton from '@/components/DropdownButton.vue';

export default {
  components: {
    DropdownButton,
  },
  props: {
    type: { type: String, required: true },
  },
  computed: {
    categories() {
      return this.$store.getters['categories/all'];
    },
    currentCategory() {
      const category = this.categories.find((c) => c.key === this.type);
      return category;
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  @include text-body-small;
  display: inline-flex;
  align-items: center;
}

.category-icon {
  font-size: 16px;
  margin-right: 8px;
}

.item {
  @include text-body-small;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 12px;
}
</style>
