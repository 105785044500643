<template>
  <div class="header">
    <title-field :title="section.title" @update="updateTitle" small placeholder="Title" />
    <select
      class="selector"
      data-test="list-filter-type"
      :value="filter.type"
      @change="(e) => changeType(e.target.value)"
    >
      <option :value="null">Any</option>
      <option v-for="category in categories" :key="category.key" :value="category.key">
        {{ category.label }}
      </option>
    </select>
    <select
      class="selector"
      @change="(e) => changeHierarchy(e.target.value)"
      data-test="list-filter-hierarchy"
    >
      <option
        :value="null"
        :selected="!filter.parent && !filter.ancestor"
      >
        Any
      </option>
      <option value="children" :selected="filter.parent">
        Direct children
      </option>
      <option value="descendants" :selected="filter.ancestor">
        All descendants
      </option>
      <option
        v-for="type in relations"
        :key="type.key"
        :value="type.key"
        :selected="filter.relation === type.key"
      >{{ type.label }}
      </option>
    </select>
    <icon-button data-test="section-list-add" @click="$emit('addItem')" icon="add_circle_outline" />
  </div>
</template>

<script>
import IconButton from '@/components/IconButton.vue';
import TitleField from '../../../components/TitleField.vue';

export default {
  props: {
    article: { type: Object, required: true },
    filter: { type: Object, required: true },
    section: { type: Object, required: true },
  },
  components: {
    IconButton,
    TitleField,
  },
  computed: {
    categories() {
      return this.$store.getters['categories/all'];
    },
    relations() {
      return this.$store.getters['relations/types'];
    },
  },
  methods: {
    updateTitle(title) {
      this.$store.dispatch('articles/update', {
        id: this.article.id,
        d_section: {
          id: this.section.id,
          attrs: { title },
        },
      });
    },
    changeType(type) {
      this.$store.dispatch('articles/update', {
        id: this.article.id,
        d_section: {
          id: this.section.id,
          attrs: { filter: { ...this.section.filter, type } },
        },
      });
    },
    changeHierarchy(h) {
      const {
        children, descendants, relation, ...newFilter
      } = { ...this.section.filter };
      if (h === 'children' || h === 'descendants') {
        newFilter[h] = true;
      } else {
        newFilter.relation = h;
      }
      this.$store.dispatch('articles/update', {
        id: this.article.id,
        d_section: {
          id: this.section.id,
          attrs: { filter: newFilter },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.selector {
  background: none;
  border: 0;
  color: var(--color-text);;
  font-weight: 600;
  font-family: 'PT Sans', sans-serif;
  outline: none;
  text-align: right;
  margin-right: 8px;
  height: 32px;
  margin-top: -1px;
}
</style>
