<template>
  <ul class="breadcrumbs">
    <li class="item" v-for="article in articles" :key="article.id">
      <router-link
        :to="{ name: 'article', params: { id: article.id } }"
        class="link"
      >
        {{ article.title }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    articles: { type: Array, required: true },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.item {
  @include text-body-small;
  & + &::before {
    content: ">";
    margin: 0 6px;
  }
}

.link {
  color: inherit;
  text-decoration: none;
}
</style>
