import paper from 'paper';
import brushes from './brushes';

const toolTerrain = {
  data() {
    return {
      terrain: {
        remove: false,
        tempShape: null,
      },
    };
  },
  mounted() {
    this.onTool('terrain', this.enableTerrainTool);
    this.onTool('terrainpen', this.enableTerrainPen);
    this.onTool('water', () => {
      this.enableTerrainTool(true);
    });
    this.onTool('waterpen', () => {
      this.enableTerrainPen(true);
    });
  },
  methods: {
    enableTerrainTool(remove = false) {
      this.terrain.remove = remove;
      this.activateLayer('terrain');
      this.tool.minDistance = 0;

      // Make sure the layers are there
      if (this.layers.terrain.children.length === 0) {
        // eslint-disable-next-line no-new
        new paper.Path(); new paper.Path(); new paper.Path({
          fillColor: this.terrainFill,
          strokeColor: this.strokeColor,
        });
      }

      this.tool.onMouseDown = this.terrainStartPaint;
      this.tool.onMouseDrag = this.terrainPaint;
      this.tool.onMouseUp = this.terrainEndPaint;
    },
    enableTerrainPen(remove = false) {
      const { terrain } = this.layers;
      this.terrain.remove = remove;
      terrain.activate();
      this.tool.minDistance = 0;

      this.tool.onMouseDown = this.terrainPenStart;
      this.tool.onMouseDrag = this.terrainPenAddPoint;
      this.tool.onMouseUp = this.terrainPenEnd;
    },
    terrainPenStart(event) {
      this.terrain.path = new paper.Path({
        strokeColor: this.strokeColor,
      });
      this.terrainPenAddPoint(event);
    },
    terrainPenAddPoint(event) {
      this.terrain.path.add(event.point);
    },
    terrainPenEnd() {
      const { path } = this.terrain;
      let land = this.terrainGetShape();
      path.closed = true;
      path.simplify(2);
      if (this.terrain.remove) {
        land = land.subtract(path, { insert: false });
      } else {
        land = land.unite(path, { insert: false });
      }
      this.layers.terrain.removeChildren();
      this.layers.terrain.addChild(land);
      this.updateWaves(land);
    },
    terrainStartPaint() {
      this.tool.minDistance = this.radius * 0.7;
      this.terrain.tempShape = new paper.CompoundPath({
        fillColor: this.terrain.remove ? this.waterFill : this.terrainFill,
      });
    },
    terrainPaint(event) {
      const brush = new paper.Path({
        pathData: brushes.BRUSH,
        scaling: this.radius / 10,
        rotation: Math.random() * 360,
        // fillColor: this.terrain.remove ? this.waterFill : this.terrainFill,
        data: {
          brushStroke: true,
        },
        insert: false,
      });
      // brush.simplify(5);
      brush.position = event.point;
      // this.terrain.tempShape = this.terrain.tempShape.unite(brush);
      this.terrain.tempShape.addChild(brush);
    },
    terrainEndPaint() {
      /* this.layers.terrain.children.forEach((child) => {
        if (child.data.brushStroke) {
          this.terrain.tempShape = this.terrain.tempShape.unite(child);
        }
      }); */
      let land = this.terrainGetShape();
      const oldShape = land;
      if (this.terrain.remove) {
        land = land.subtract(this.terrain.tempShape, { insert: false });
      } else {
        land = land.unite(this.terrain.tempShape, { insert: false });
      }
      this.layers.terrain.removeChildren();
      this.layers.terrain.addChild(land);
      this.updateWaves(land);

      this.historyAdd(
        this.terrainUndo(oldShape),
        this.terrainUndo(land),
      );
    },
    terrainGetShape() {
      return this.layers.terrain.children[2];
    },
    terrainUndo(shape) {
      return () => {
        this.layers.terrain.removeChildren();
        this.layers.terrain.addChild(shape);
        this.updateWaves(shape);
      };
    },
    updateWaves(land) {
      const { terrain } = this.layers;
      let [waves, offset] = terrain.children;
      offset = land.clone();
      waves = land.clone();
      terrain.removeChildren();
      offset.strokeWidth = 9;
      offset.strokeColor = this.waterFill;
      waves.strokeWidth = 10;
      waves.dashArray = [20, 7];
      waves.strokeColor = this.strokeColor;
      terrain.addChild(land);
      offset.insertBelow(land);
      waves.insertBelow(offset);
    },

  },

};
export default toolTerrain;
