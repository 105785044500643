<template>
  <div>
    <SelectMenu v-if="editor" :editor="editor" />
    <editor-content :editor="editor" :class="rootClass" />
  </div>
</template>

<script>
import tippy from 'tippy.js';
import {
  Editor, EditorContent, VueRenderer,
} from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Mention from './editor/MentionExtension';
import MentionList from './editor/MentionList.vue';
import Cutout from './editor/CutoutExtension';
import CutoutSuggestions from './editor/CutoutSuggestions.vue';
import CreationMenu from './editor/CreationMenu.vue';
import CreationExtension from './editor/CreateExtension';
import SelectMenu from './editor/SelectMenu.vue';

export default {
  components: {
    SelectMenu,
    EditorContent,
  },
  props: {
    content: { default: null },
    small: { type: Boolean, default: false },
  },
  data() {
    return {
      editor: null,
    };
  },
  computed: {
    rootClass() {
      return {
        'content-editor': true,
        'content-editor--small': this.small,
      };
    },
  },
  watch: {
    content(newVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(this.editor.getJSON())) {
        this.editor.commands.setContent(newVal, false);
      }
    },
  },
  mounted() {
    const { handleUpdate } = this;
    this.editor = new Editor({
      onUpdate({ editor }) {
        handleUpdate(editor.getJSON());
      },
      content: this.content,
      extensions: [
        StarterKit,
        Placeholder,
        Cutout.configure({
          suggestion: {
            items: this.search,
            render: () => {
              let component;
              let popup;
              return {
                onStart: (props) => {
                  component = new VueRenderer(CutoutSuggestions, {
                    editor: this.editor,
                    props,
                  });
                  popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                  });
                },
                onUpdate(props) {
                  component.updateProps(props);
                  popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                  });
                },
                onKeyDown(props) {
                  return component?.ref?.onKeyDown(props);
                },
                onExit() {
                  popup[0].destroy();
                  component.destroy();
                },
              };
            },
          },
        }),
        CreationExtension.configure({
          suggestion: {
            render: () => {
              let component;
              let popup;
              return {
                onStart: (props) => {
                  component = new VueRenderer(CreationMenu, {
                    editor: this.editor,
                    props,
                  });
                  popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                  });
                },
                onUpdate(props) {
                  component.updateProps(props);
                  popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                  });
                },
                onKeyDown(props) {
                  return component.ref?.onKeyDown(props);
                },
                onExit() {
                  popup[0].destroy();
                  component.destroy();
                },
              };
            },
          },
        }),
        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
          suggestion: {
            items: this.search,
            render: () => {
              let component;
              let popup;
              return {
                onStart: (props) => {
                  component = new VueRenderer(MentionList, {
                    editor: this.editor,
                    props,
                  });
                  popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                  });
                },
                onUpdate(props) {
                  component.updateProps(props);
                  popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                  });
                },
                onKeyDown(props) {
                  return component.ref?.onKeyDown(props);
                },
                onExit() {
                  popup[0].destroy();
                  component.destroy();
                },
              };
            },
          },
        }),
      ],
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    handleUpdate(content) {
      this.$emit('update', content);
    },
    async search({ query }) {
      console.log(query);
      const articles = await this.$store.dispatch('articles/search', query);
      return articles;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-editor {
  @include text-body;
  color: var(--color-text);;
  ::v-deep strong {
    font-weight: 500;
  }
  ::v-deep p {
    margin: 0;
  }
  ::v-deep p + p {
    margin-top: 8px;
  }
  ::v-deep(ul) {
    list-style-type: disc;
    margin: 8px 0 8px 24px;
  }
  ::v-deep(ol) {
    list-style-type: decimal;
    margin: 8px 0 8px 24px;
  }
  ::v-deep(em) {
    font-style: italic;
  }

  &--small {
    font-size: 14px;
  }
}
::v-deep .ProseMirror:focus {
  outline: none;
}

::v-deep .ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: var(--color-input-placeholder-text);
  pointer-events: none;
  height: 0;
}
</style>
