<template>
  <div class="items">
    <button>Create item</button>
  </div>
</template>

<script>
export default {
  props: {
    command: {
      type: Function,
      required: true,
    },
    query: {
      type: String,
      required: true,
    },
  },

  computed: {
    categories() {
      return this.$store.getters['categories/all'];
    },
  },

  methods: {
    onKeyDown({ event }) {
      if (event.key === 'Enter') {
        this.enterHandler();
        return true;
      }

      if (event.key === 'Escape') {
        // TODO close
        return true;
      }

      return false;
    },

    enterHandler() {
      this.createItem();
    },

    createItem() {
      let type = 'default';
      let title = this.query;
      const parts = title.split(':');
      if (parts.length > 1) {
        const first = parts.shift();
        if (this.categories.find((c) => c.key === first)) {
          type = first;
          title = parts.join(':');
        }
      }
      this.$store.dispatch('articles/create', { title, type }).then(({ id }) => {
        this.command({ id });
      });

      /* const item = this.items[index];
      if (item) {
        this.command({ id: item.id });
      } */
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
