<template>
  <div>
    <div class="path">
      <fetch-data
        v-if="ids.length"
        :get="{ articles: 'articles/ids', attrs: { ids } }"
      >
        <template #default="{ articles }">
          <Breadcrumbs :articles="articles" data-test="article-breadcrumbs" />
        </template>
      </fetch-data>

      <DropdownButton>
        <template #trigger>
          <button class="edit-button" data-test="change-location">
            <span class="material-icons-outlined">edit</span>
          </button>
        </template>

        <template #default="{close}">
          <div>
            <input data-test="new-location" placeholder="Find article..." v-model="search" />
          </div>
          <fetch-data
            v-if="search.length"
            :get="{ results: 'articles/type', attrs: { search } }"
          >
            <template #default="{ results }">
              <ul>
                <li
                  v-for="result in results"
                  :key="result.id"
                  data-test="new-location-suggestion"
                  @click="changeLocation(result); close()"
                >
                  {{ result.title }}
                </li>
              </ul>
            </template>
          </fetch-data>
        </template>
      </DropdownButton>

    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DropdownButton from '@/components/DropdownButton.vue';

export default {
  components: {
    Breadcrumbs,
    DropdownButton,
  },
  props: {
    article: { type: Object, required: true },
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ids() {
      return this.article.path ? this.article.path.split('.') : [];
    },
  },
  methods: {
    changeLocation(newParent) {
      this.$store.dispatch('articles/update', {
        id: this.article.id,
        path: newParent.path ? `${newParent.path}.${newParent.id}` : newParent.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.path {
  display: flex;
  align-items: center;
}

.edit-button {
  padding: 0;
  // border: 1px solid #999;
  border:0;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border-radius: 50%;
  background: #fff;
  margin-left: 8px;
  opacity: 0;
  transition: opacity 0.15s ease-out;

  .path:hover & {
    opacity: 1;
  }

  span {
    font-size: 14px;
    color: #999;
  }
}
</style>
