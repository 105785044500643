const TREES = [
  'M2.28001 0.578411C2.62663 0.274944 3.05839 0 3.5 0C3.63138 0 3.74432 0.0407387 3.81774 0.0730996C3.8985 0.108692 3.97719 0.154962 4.05047 0.203987C4.19741 0.302286 4.35821 0.435725 4.5227 0.590809C4.85338 0.902602 5.23361 1.33476 5.59396 1.83775C6.30247 2.82674 7 4.18196 7 5.50002C7 6.72986 6.71856 7.82401 6.12692 8.62422C5.61484 9.31681 4.89067 9.7596 4 9.8791V14H3V9.8791C2.10933 9.7596 1.38516 9.31681 0.873083 8.62422C0.281439 7.82401 0 6.72986 0 5.50002C0 4.21016 0.548496 2.86566 1.20602 1.85607C1.53732 1.34738 1.90981 0.902529 2.28001 0.578411Z',
  'M4 12.883C2.94854 12.7595 2.07844 12.243 1.47104 11.4215C0.796128 10.5086 0.5 9.29826 0.5 8C0.5 6.6363 1.06237 4.73783 1.70573 3.23531C2.03325 2.47039 2.40724 1.74764 2.78672 1.19855C2.97457 0.926749 3.18767 0.66233 3.42312 0.455003C3.63106 0.271904 4.00512 0 4.5 0C4.74631 0 4.93464 0.0844406 5.03955 0.14293C5.1501 0.204564 5.23537 0.275199 5.29344 0.328662C5.40882 0.434897 5.5058 0.556646 5.58116 0.660261C5.73651 0.873834 5.90003 1.15046 6.06109 1.45099C6.38773 2.06047 6.76219 2.87775 7.11529 3.75584C7.46905 4.63554 7.80994 5.59756 8.06403 6.49865C8.3124 7.37945 8.5 8.27755 8.5 9C8.5 10.2878 8.20638 11.3712 7.37724 12.0849C6.7128 12.6568 5.85856 12.8507 5 12.8986V16.5H4V12.883Z',
  'M4.5 10.7875C5.09674 10.6677 5.59687 10.3423 5.99219 9.94607C6.48489 9.45218 6.86098 8.80717 7.14418 8.14559C7.71016 6.82346 8 5.21029 8 3.92511C8 3.01479 7.59273 2.31031 7.03478 1.81001C6.50325 1.3334 5.83709 1.03895 5.23791 0.850233C4.03899 0.472623 2.80046 0.42511 2.5 0.42511C1.83839 0.42511 1.35787 0.798548 1.05523 1.18658C0.760046 1.56505 0.559341 2.04206 0.417049 2.51711C0.129282 3.47784 0 4.70831 0 5.92511C0 7.22337 0.296128 8.43374 0.97104 9.34657C1.57844 10.1681 2.44854 10.6846 3.5 10.8081V14.4251H4.5V10.7875Z',
];
const MTS = [
  {
    fill: 'M46.5 21.5349C49.3 25.1349 56.6667 36.7217 60.5 41.555L35 47L1 41.555C4.83333 36.7216 15.4 17.8349 17 15.0349C19 11.5349 27.5 9.53491 30 7.53491C32.5 5.53491 35 0.534935 37.5 1.03493C40 1.53493 43 17.0349 46.5 21.5349Z',
    stroke: 'M1 41.555C4.83333 36.7216 15.4 17.8349 17 15.0349C19 11.5349 27.5 9.53491 30 7.53491C32.5 5.53491 35 0.534935 37.5 1.03493C40 1.53493 43 17.0349 46.5 21.5349C49.3 25.1349 56.6667 36.7217 60.5 41.555',
  },
  {
    fill: 'M45.5 17.02C48.3 20.62 56.6667 31.1867 60.5 36.0201L32.5 41L1 36.0201C4.83333 31.1867 12.9 20.82 14.5 18.02C16.5 14.52 20.5 13.52 23 11.52C25.5 9.52005 28 0.520048 30.5 1.02005C33 1.52005 42 12.52 45.5 17.02Z',
    stroke: 'M1 36.0201C4.83333 31.1867 12.9 20.82 14.5 18.02C16.5 14.52 20.5 13.52 23 11.52C25.5 9.52005 28 0.520048 30.5 1.02005C33 1.52005 42 12.52 45.5 17.02C48.3 20.62 56.6667 31.1867 60.5 36.0201',
  },
  {
    fill: 'M42.5 17.5495C45.3 21.1495 56.6667 30.7162 60.5 35.5496L32.5 41L1 35.5496C4.83333 30.7162 13.4 22.8295 15 20.0295C17 16.5295 20 10.5295 22.5 8.52953C25 6.52953 30 0.529473 32.5 1.02947C35 1.52947 39 13.0495 42.5 17.5495Z',
    stroke: 'M1 35.5496C4.83333 30.7162 13.4 22.8295 15 20.0295C17 16.5295 20 10.5295 22.5 8.52953C25 6.52953 30 0.52948 32.5 1.02948C35 1.52948 39 13.0495 42.5 17.5495C45.3 21.1495 56.6667 30.7162 60.5 35.5496',
  },
];
const BRUSH = 'M24.428 1.19671C24.0432 -2.47523 19.3122 3.4233 18.8303 3.97634C18.4003 2.95077 17.4431 1.17819 17.1051 2.48803C16.6255 4.34705 16.6152 3.97634 15.3616 3.97634C13.8509 3.97634 13.8155 4.8998 12.8105 5.85861C12.7199 5.94503 10.213 5.55219 9.8923 5.55219C8.64855 5.55219 7.42606 5.94616 6.12989 5.94616C4.88821 5.94616 5.28564 8.13496 5.28564 9.09787C5.28564 10.6136 5.63524 12.2664 4.12939 12.6654C2.68198 13.049 3.68208 15.1539 3.63385 16.1892C3.56483 17.6708 -2.48343 18.0627 1.17452 20.3477C1.89269 20.7963 2.97253 21.7027 3.26679 22.6677C3.37273 23.0152 3.14798 23.7079 3.3769 23.9809C4.32475 25.1113 6.63 24.1478 7.57979 25.4692C8.45214 26.6829 8.52565 27.2202 9.91065 27.2202C10.4057 27.2202 9.75949 30.2115 9.8923 30.7659C10.1315 31.7643 13.4963 29.7285 13.5446 30.7659C13.5848 31.6291 15.6897 33.5943 16.3343 33.8738C17.3662 34.3213 18.1742 33.4768 18.9955 32.9327C20.1049 32.1976 20.5341 33.4614 21.4732 33.5236C22.8395 33.6141 24.3588 32.6866 25.621 32.1447C26.6202 31.7158 29.1045 31.7822 29.8055 30.8534C30.5593 29.8546 29.7886 27.9475 31.3839 28.7085C32.3792 29.1833 34.6875 29.259 34.6875 27.6141C34.6875 26.6695 34.4374 25.1954 35.0179 24.4624C36.125 23.0645 37 23.0425 37 20.9387C37 19.9994 36.2734 18.3857 35.8254 17.5462C35.4337 16.8122 34.9921 16.1421 34.543 15.4605L34.504 15.4013C33.9534 14.5656 33.1036 14.8747 32.5218 14.2194C31.4876 13.0545 34.7002 11.2192 34.3388 9.7107C34.2553 9.36242 31.2375 8.89101 30.7232 8.30994C29.8224 7.29229 28.1765 6.49188 26.9241 6.36201C25.343 6.19805 24.7768 6.80182 24.7768 4.76427C24.7768 3.45861 24.5578 2.43489 24.428 1.19671Z';

export default {
  TREES,
  MTS,
  BRUSH,
};
