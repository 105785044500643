<template>
  <div>
    <ul>
      <li class="rel-item" v-for="(section, index) in mapped" :key="index">
        <span class="rel-label">{{ section.label }}</span>
        <router-link
          class="rel-link"
          v-for="article in section.articles" :key="article.id"
          :to="{ name: 'article', params: { id: article.id } }"
        >
          {{ article.title }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    id: { type: String, required: true },
    relations: { type: Array, required: true },
  },
  computed: {
    relationTypes() {
      return this.$store.getters['relations/types'];
    },
    mapped() {
      const mapped = {};
      this.relations.forEach((relation) => {
        let key = relation.type;
        if (relation.from === this.id) {
          key += ':to';
        } else {
          key += ':from';
        }
        if (typeof mapped[key] === 'undefined') {
          mapped[key] = [];
        }
        mapped[key].push(relation.article);
      });
      return Object.keys(mapped).map((k) => ({
        label: this.relationTypes.find((t) => t.key === k).label,
        articles: mapped[k],
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.rel-item {
  & + & {
    border-top: 1px solid #46484E;
    margin-top: 10px;
    padding-top: 13px;
  }
}
.rel-label {
  line-height: 16px;
  font-size: 14px;
  color: var(--color-text);
}

.rel-link {
  display: block;
  color: inherit;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  font-size: 16px;
  color: var(--color-text);
}
</style>
