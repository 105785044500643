<template>
  <div class="article-header">
    <CategorySelector :type="article.type" @update="updateType" />
    <div class="divider" />
    <ArticlePath :article="article" class="article-path" />
  </div>
</template>

<script>
import ArticlePath from './ArticlePath.vue';
import CategorySelector from './CategorySelector.vue';

export default {
  props: {
    article: { type: Object, required: true },
  },
  components: {
    ArticlePath,
    CategorySelector,
  },
  methods: {
    updateType({ key }) {
      this.$store.dispatch('articles/update', {
        id: this.article.id,
        type: key,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.article-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  padding: 24px 64px 0;
  align-items: center;
}

.divider {
  width: 1px;
  height: 16px;
  background: var(--color-text-faded);
  margin: 0 12px;
}
</style>
