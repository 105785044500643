import { Node, mergeAttributes } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import CutoutComponent from './NestedCutoutComponent.vue';

export default Node.create({
  name: 'cutout',

  group: 'block',

  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => ({
          id: element.getAttribute('data-id'),
        }),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }

          return {
            'data-id': attributes.id,
          };
        },
      },
      part: {
        default: null,
        parseHTML: (element) => ({
          part: element.getAttribute('data-part'),
        }),
        renderHTML: (attributes) => {
          if (!attributes.part) {
            return {};
          }

          return {
            'data-part': attributes.part,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'cutout',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['cutout', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(CutoutComponent);
  },
});
