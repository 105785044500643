<template>
  <button class="icon-button">
    <span class="material-icons-outlined">{{ icon }}</span>
  </button>
</template>

<script>
export default {
  props: {
    icon: { type: String, required: true },
  },
};
</script>

<style lang="scss" scoped>
.icon-button {
  background: none;
  border: none;
  padding: 0;
  color: var(--color-text);
}
</style>
