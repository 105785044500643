<template>
  <div data-test="mentions-incoming">
    <h2 class="headline">Incoming mentions</h2>
    <fetch-data
      :get="{ articles: 'articles/mentions', attrs: { id: article.id } }"
    >
      <template #default="{ articles }">
        <ul>
          <li v-for="article in articles" :key="article.id">
            <router-link :to="{ name: 'article', params: { id: article.id } }">
              {{ article.title }}
            </router-link>
          </li>
        </ul>
      </template>
    </fetch-data>
  </div>
</template>

<script>
export default {
  props: {
    article: { type: Object, required: true },
  },
};
</script>

<style lang="scss" scoped>
.headline {
  font-size: 16px;
  font-weight: 600;
}
</style>
