<template>
  <node-view-wrapper
    class="mention-component"
    :data-id="id"
    data-test="mention-item"
  >
    <fetch-data :get="{ article: 'articles/single', attrs: { id } }" combine>
      <template #default="{ article }">
        <span @click="openPage" class="mention-component__link">{{
          article ? article.title : ""
        }}</span>
      </template>
    </fetch-data>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';

export default {
  components: {
    NodeViewWrapper,
  },

  props: nodeViewProps,

  methods: {
    openPage() {
      this.$router.push({ name: 'article', params: { id: this.id } });
    },
  },

  computed: {
    id() {
      return this.node.attrs.id;
    },
  },
};
</script>

<style lang="scss">
.mention-component {
  display: inline-block;
  font-weight: 600;
}

.mention-component__link {
  cursor: pointer;
}
</style>
