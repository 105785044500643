<template>
  <node-view-wrapper class="cutout" data-test="cutout">
    <fetch-data :get="{ article: 'articles/single', attrs: { id } }">
      <template #default="{ article }">
        <div class="cutout__header">
          <div class="cutout__titlewrap">
            <article-icon :article="article" small class="cutout__icon" />
            <router-link
              :to="{ name: 'article', params: { id: article.id } }"
              class="cutout__title"
            >
              {{ article.title }}
            </router-link>
          </div>
          <div class="cutout__part">
            <select
              data-test="cutout-sections"
              class="cutout__selector"
              @change="changePart($event.target.value)"
            >
              <option value="body">Body</option>
              <option
                v-for="section in mapSections(article.sections)"
                :value="section.id"
                :key="section.id"
                :selected="node.attrs.part === section.id"
                >
                {{ section.title }}
              </option>
            </select>
            <icon-button
              data-test="cutout-sections-add"
              @click="addPart(article.sections)"
              icon="add_circle_outline"
            />
          </div>
        </div>
        <title-field
          v-if="node.attrs.part !== 'body' && article.sections[node.attrs.part]"
          :title="article.sections[node.attrs.part].title"
          @update="updateTitle"
          placeholder="Section title"
          small
          data-test="cutout-title"
        />
        <simple-editor
          data-test="cutout-editor"
          :content="node.attrs.part === 'body' ?
            article[node.attrs.part] :
            article.sections[node.attrs.part].content"
          @update="save"
        />
      </template>
    </fetch-data>
  </node-view-wrapper>
</template>

<script>
import TitleField from '@/components/TitleField.vue';
import IconButton from '@/components/IconButton.vue';
import ArticleIcon from '@/components/ArticleIcon.vue';

import {
  NodeViewWrapper, nodeViewProps,
} from '@tiptap/vue-3';
import SimpleEditor from './SimpleEditor.vue';

export default {
  props: nodeViewProps,
  components: {
    NodeViewWrapper,
    SimpleEditor,
    TitleField,
    IconButton,
    ArticleIcon,
  },
  data() {
    return {
      subeditor: null,
    };
  },
  computed: {
    id() {
      return this.node.attrs.id;
    },
  },
  methods: {
    async save(value) {
      const payload = {
        id: this.id,
      };
      if (this.node.attrs.part === 'body') {
        payload.body = value;
      } else {
        payload.d_section = {
          id: this.node.attrs.part,
          attrs: { content: value },
        };
      }
      this.$store.dispatch('articles/update', payload);
    },
    updateTitle(title) {
      this.$store.dispatch('articles/update', {
        id: this.id,
        d_section: {
          id: this.node.attrs.part,
          attrs: { title },
        },
      });
    },
    changePart(part) {
      this.updateAttributes({
        part,
      });
    },
    mapSections(sections) {
      if (!sections) return [];
      const mapped = Object.keys(sections).map((key) => ({
        id: key,
        ...sections[key],
      }));
      return mapped.sort((a, b) => a.order - b.order);
    },
    addPart(existingSections) {
      // Push the new section to the end of the article
      const after = existingSections ? Object.keys(existingSections).length : 0;
      this.$store.dispatch('articles/addSection', {
        id: this.id,
        type: 'text',
        after,
        title: '',
      }).then(({ sections }) => {
        const id = Object.keys(sections).find((k) => sections[k].order === after + 1);
        if (id) this.changePart(id);
      });
    },
  },
};
</script>

<style lang="scss">
.cutout {
  padding: 16px;
  margin: 24px 0;
  background: var(--color-card-background);
  border-radius: 4px;
  font-size: 14px;
}

.cutout__header {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cutout__titlewrap {
  display: flex;
  align-items: center;
}

.cutout__icon {
  margin-right: 8px;
}

.cutout__title {
  color: var(--color-text);
  @include text-cutout-title;

  &:hover {
    text-decoration: underline;
  }
}

.cutout__selector {
  background: none;
  border: 0;
  color: var(--color-text);
  font-weight: 600;
  font-family: 'PT Sans', sans-serif;
  outline: none;
  text-align: right;
  margin-right: 8px;
  height: 24px;
  margin-top: -4px;
}

.cutout__part {
  font-size: 14px;
  display: flex;
  align-items: center;
}
</style>
