import { Node } from '@tiptap/core';
import Suggestion from '@tiptap/suggestion';

const Mention = Node.create({
  name: 'create',

  defaultOptions: {
    HTMLAttributes: {},
    suggestion: {
      char: '+',
      allowSpaces: true,
      command: ({ editor, range, props }) => {
        editor
          .chain()
          .focus()
          .insertContentAt(range, [
            {
              type: 'mention',
              attrs: props,
            },
            {
              type: 'text',
              text: ' ',
            },
          ])
          .run();
      },
      allow: ({ editor, range }) => editor.can().insertContentAt(range, { type: 'mention' }),
    },
  },

  group: 'inline',

  inline: true,

  selectable: false,

  atom: true,

  addKeyboardShortcuts() {
    return {
      Escape: () => this.editor.commands.command(() => false),
      Backspace: () => this.editor.commands.command(({ tr, state }) => {
        let isMention = false;
        const { selection } = state;
        const { empty, anchor } = selection;

        if (!empty) {
          return false;
        }

        // eslint-disable-next-line consistent-return
        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          if (node.type.name === this.name) {
            isMention = true;
            tr.insertText(this.options.suggestion.char || '', pos, pos + node.nodeSize);

            return false;
          }
        });

        return isMention;
      }),
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});

export default Mention;
