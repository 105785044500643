import paper from 'paper';

const toolRegions = {
  data() {
    return {
      regions: {
        path: null,
      },
    };
  },
  mounted() {
    this.onTool('regions', this.enableRegionTool);
  },
  methods: {
    enableRegionTool() {
      const { regions } = this.layers;
      regions.activate();
      this.tool.minDistance = 0;

      this.tool.onMouseDown = this.regionStart;
      this.tool.onMouseDrag = this.regionAddPoint;
      this.tool.onMouseUp = this.regionEnd;
    },
    regionStart(event) {
      this.regions.path = new paper.Path({
        data: { type: 'region' },
        strokeColor: this.strokeColor,
      });
      this.regionAddPoint(event);
    },
    regionAddPoint(event) {
      this.regions.path.add(event.point);
    },
    regionEnd() {
      const { path } = this.regions;
      path.closed = true;
      path.simplify(10);
      path.flatten(20);
      let clipped = path.clone({ insert: false });
      clipped.fillColor = '#fee';
      clipped.strokeColor = '#faa';
      path.parent.children.forEach((child) => {
        if (child.id !== path.id) clipped = clipped.subtract(child, { insert: false });
      });
      clipped = clipped.intersect(this.terrainGetShape());
      clipped.insertAbove(path);
      path.remove();

      this.historyAdd(
        this.regionUndo(clipped),
        this.regionRedo(clipped),
      );
    },
    regionUndo(path) {
      return () => {
        path.remove();
      };
    },
    regionRedo(path) {
      return () => {
        this.layers.lines.addChild(path);
      };
    },
  },

};
export default toolRegions;
