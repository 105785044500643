<template>
  <div>
    <ArticleHeader :article="article" />
    <div :class="rootClass">
      <div class="inner">
        <title-field
          class="article-title"
          data-test="article-title"
          :title="article.title"
          @update="updateTitle"
          :placeholder="`Untitled ${article.type}`"
          :small="simple"
        />

        <content-editor
          data-test="article-body"
          :content="article.body"
          @update="updateBody"
          :small="simple"
        />

        <div class="add-wrapper">
          <button
            data-test="article-section-add:text"
            @click="addSection(-1, 'text')"
          >
            + Text
          </button>
          <button
            data-test="article-section-add:list"
            @click="addSection(-1, 'list', { filter: {} })"
          >
            + List
          </button>
        </div>
        <div v-if="!simple">
        <div
          v-for="section in sortSections(article.sections)"
          :key="section.id"
          class="article-section"
        >
          <div class="article-section__actions">
            <icon-button
              data-test="article-section-up"
              @click="moveSection(section, article.sections, -1)"
              icon="expand_less"
            />
            <icon-button
              data-test="article-section-down"
              @click="moveSection(section, article.sections, 1)"
              icon="expand_more"
            />
            <br />
            <icon-button
              class="article-remove"
              data-test="article-section-remove"
              @click="removeSection(section.id)"
              icon="delete_outline"
            />
          </div>
          <section-text
            v-if="section.type === 'text'"
            :section="section"
            :article="article"
          />
          <section-list
            v-else-if="section.type === 'list'"
            :section="section"
            :article="article"
          />
          <div class="add-wrapper">
            <button
              data-test="article-section-add:text"
              @click="addSection(section.order, 'text')"
            >
              + Text
            </button>
            <button
              data-test="article-section-add:list"
              @click="addSection(section.order, 'list', { filter: {} })"
            >
              + List
            </button>
          </div>
        </div>
        </div>
      </div>
      <div class="relations" v-if="!simple">
        <article-relations class="relations-rels" :article="article" />
        <incoming-mentions class="relations-mens" :article="article" />
      </div>
    </div>
  </div>
</template>

<script>
import ContentEditor from '@/components/ContentEditor.vue';
import IconButton from '@/components/IconButton.vue';
import ArticleRelations from './ArticleRelations.vue';
import IncomingMentions from './IncomingMentions.vue';
import ArticleHeader from './ArticleHeader.vue';
import SectionText from './sections/SectionText.vue';
import SectionList from './sections/SectionList.vue';
import TitleField from '../../components/TitleField.vue';

export default {
  props: {
    article: { type: Object, required: true },
    simple: { type: Boolean, default: false },
  },
  components: {
    ContentEditor,
    IncomingMentions,
    ArticleRelations,
    ArticleHeader,
    SectionText,
    SectionList,
    TitleField,
    IconButton,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    rootClass() {
      return {
        wrapper: true,
        'wrapper--simple': this.simple,
      };
    },
  },
  methods: {
    updateTitle(title) {
      this.$emit('titleUpdated', title);
      this.$store.dispatch('articles/update', {
        id: this.article.id,
        title,
      });
    },
    updateBody(body) {
      this.$store.dispatch('articles/update', {
        id: this.article.id,
        body,
      });
    },
    sortSections(sections) {
      if (!sections) return [];
      const mapped = Object.keys(sections).map((key) => ({
        id: key,
        ...sections[key],
      }));
      return mapped.sort((a, b) => a.order - b.order);
    },
    addSection(order, type, other) {
      this.$store.dispatch('articles/addSection', {
        id: this.article.id,
        after: order,
        type,
        ...other,
      });
    },
    removeSection(sectionId) {
      this.$store.dispatch('articles/removeSection', {
        id: this.article.id,
        sectionId,
      });
    },
    moveSection(section, sections, direction) {
      const oldOrder = section.order;
      const newOrder = oldOrder + direction;
      const sectionsToUpdate = [
        { id: section.id, attrs: { order: newOrder } },
      ];
      Object.keys(sections).forEach((key) => {
        const otherSection = sections[key];
        const { order } = otherSection;
        if (direction > 0 && order > oldOrder && order <= newOrder) {
          sectionsToUpdate.push({
            id: key,
            attrs: { order: order - 1 },
          });
        } else if (direction < 0 && order < oldOrder && order >= newOrder) {
          sectionsToUpdate.push({
            id: key,
            attrs: { order: order + 1 },
          });
        }
      });
      this.$store.dispatch('articles/update', {
        id: this.article.id,
        d_sections: sectionsToUpdate,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin: auto;
  display: flex;
  justify-content: center;
}

.inner {
  flex: 1;
  max-width: 630px;
  margin-right: 96px;

  .wrapper--simple & {
    margin-right: 0;
  }
}

.relations {
  width: 300px;
  padding-top: 40px;
}

.article-path {
  margin-bottom: 8px;
}

.article-title {
  margin-bottom: 16px;

  .wrapper--simple & {
    margin-bottom: 4px;
  }
}

.add-wrapper {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  position: relative;
  transition: opacity 0.15s ease-out;

  &::after {
    content: "";
    height: 1px;
    background: #ccc;
    position: absolute;
    z-index: -1;
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }

  button {
    border: 1px solid #ccc;
    border-radius: 20px;
    color: #ccc;
    background: #fff;
    font-size: 12px;
    font-weight: 600;

    + button {
      margin-left: 8px;
    }
  }
}

.article-section {
  position: relative;
}

.article-section__actions {
  position: absolute;
  top: 0;
  left: -40px;
  opacity: 0;
  width: 40px;
  padding-bottom: 40px;

  .article-section:hover & {
    opacity: 0.6;
  }
}

 .relations-rels {
   background: var(--color-card-background);
   padding: 24px 16px;
   margin-bottom: 32px;
   border-radius: 4px;
 }

 .relations-mens {
  background: var(--color-card-background);
  padding: 24px 16px;
  border-radius: 4px;
 }

</style>
