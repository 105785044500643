import paper from 'paper';

const zoom = (map, event) => {
  let newZoom = map.view.zoom;
  const oldZoom = map.view.zoom;
  if (event.deltaY < 0) {
    newZoom = map.view.zoom * 1.03;
  } else {
    newZoom = map.view.zoom * 0.97;
  }
  if (newZoom < 0.1) newZoom = 0.1;

  const beta = oldZoom / newZoom;
  const mousePosition = new map.Point(event.offsetX, event.offsetY);

  // viewToProject: gives the coordinates in the Project space from the Screen Coordinates
  const viewPosition = map.view.viewToProject(mousePosition);

  const mpos = viewPosition;
  const ctr = map.view.center;

  const pc = mpos.subtract(ctr);
  const offset = mpos.subtract(pc.multiply(beta)).subtract(ctr);

  // eslint-disable-next-line no-param-reassign
  map.view.zoom = newZoom;
  // eslint-disable-next-line no-param-reassign
  map.view.center = map.view.center.add(offset);
};

const movement = {
  data() {
    return {
      history: [],
      redoHistory: [],
    };
  },
  methods: {
    // TODO Move to another file (and move keychecks to main file)
    addToHistory(command) {
      console.log('History: adding');
      this.history.push(command);
      if (this.history.length > 10) {
        this.history.shift();
      }
      this.redoHistory = [];
    },
    historyAdd(undo, redo) {
      this.addToHistory({
        undo, redo,
      });
    },
    undo() {
      const command = this.history.pop();
      console.log('Undoing with command', command);
      if (command) {
        command.undo();
        this.redoHistory.push(command);
      }
    },
    redo() {
      const command = this.redoHistory.pop();
      if (command) {
        console.log('Redoing with command', command);
        command.redo();
        this.history.push(command);
      }
    },
    addZoomFunction() {
      this.$refs.canvaswrap.addEventListener('wheel', (event) => {
        zoom(paper, event);
        this.zoom = paper.view.zoom;
        this.viewPos = paper.view.bounds;
        event.stopPropagation();
        event.preventDefault();
      });
    },
    addPanFunction() {
      let moved = false;
      let undoStarted = false;
      let redoStarted = false;
      let origZoom = paper.view.zoom;
      let beta = 1;
      let wrapperBounds;
      this.$refs.wrapper.addEventListener('gesturechange', (e) => {
        if (!redoStarted) {
          const oldZoom = paper.view.zoom;
          paper.view.zoom = origZoom * e.scale;
          beta = oldZoom / paper.view.zoom;
          this.zoom = paper.view.zoom;
          this.viewPos = paper.view.bounds;
          moved = true;
          console.log('Gesture change');
        }
        e.preventDefault();
      });
      this.$refs.wrapper.addEventListener('touchstart', (event) => {
        moved = false;
        const fingers = event.touches.length;
        if (event.touches[0].touchType === 'direct') {
          this.disableAllTools();
        }
        if (fingers > 1) {
          wrapperBounds = this.$refs.wrapper.getBoundingClientRect();
          this.disableAllTools();
          if (fingers === 2) {
            undoStarted = true;
          }
          if (fingers === 3) {
            undoStarted = false;
            redoStarted = true;
          }
        }
      });
      this.$refs.wrapper.addEventListener('touchmove', (event) => {
        const fingers = event.touches.length;
        if (fingers === 2) {
          const x1 = event.targetTouches[0].pageX;
          const y1 = event.targetTouches[0].pageY;
          const x2 = event.targetTouches[1].pageX;
          const y2 = event.targetTouches[1].pageY;
          this.midpoint = [(x1 + x2) / 2 - wrapperBounds.x, (y1 + y2) / 2 - wrapperBounds.y];
          console.log((x1 + x2) / 2, y1 + y2 / 2);
          const mousePosition = new paper.Point(this.midpoint[0], this.midpoint[1]);
          console.log('wrapper Touch move', paper.view.viewToProject(mousePosition), paper.view.center);

          const mpos = paper.view.viewToProject(mousePosition);
          const ctr = paper.view.center;

          const pc = mpos.subtract(ctr);
          const offset = mpos.subtract(pc.multiply(beta)).subtract(ctr);

          // eslint-disable-next-line no-param-reassign
          paper.view.center = paper.view.center.add(offset);
        }

        moved = true;
      });
      this.$refs.wrapper.addEventListener('touchend', (event) => {
        if (event.targetTouches.length === 0) {
          if (!moved) {
            paper.view.zoom = origZoom;
            if (undoStarted) this.undo();
            if (redoStarted) this.redo();
          }
          undoStarted = false;
          redoStarted = false;
          origZoom = paper.view.zoom;
          this.midpoint = false;
          setTimeout(() => {
            this.enableCurrentTool();
          }, 100);
        }
      });

      this.tool.onKeyDown = (event) => {
        if (event.key === '0' && event.event.metaKey) {
          paper.view.zoom = 1;
          event.preventDefault();
        } else if (event.key === 'z' && event.event.shiftKey && event.event.metaKey) {
          this.redo();
          event.preventDefault();
        } else if (event.key === 'z' && event.event.metaKey) {
          this.undo();
          event.preventDefault();
        } else if (event.key === 'space') {
          event.preventDefault();
          event.stopPropagation();
          if (!this.dragging) {
            this.tool.onMouseDown = null;
            this.tool.minDistance = 0;

            this.tool.onMouseDrag = (dragEvent) => {
              const pan = dragEvent.point.subtract(dragEvent.downPoint);
              paper.view.center = paper.view.center.subtract(pan);
              this.zoom = paper.view.zoom;
              this.viewPos = paper.view.bounds;
            };

            this.tool.onKeyUp = () => {
              this.tool.onKeyUp = null;
              this.tool.onMouseDrag = null;
              this.enableTool(this.activeTool);
              this.dragging = false;
            };

            this.dragging = true;
          }
        }
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.addZoomFunction();
      this.addPanFunction();
    });
  },
};
export default movement;
