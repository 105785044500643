<template>
  <div @keydown.stop @keyup.stop @click.stop @mousedown.stop class="panel">
    Panel {{ type }}

    <template v-if="type === 'article' || type === 'region'">
      <template v-if="!id">
        <div><input data-test="new-location" v-model="search" /></div>
        <fetch-data
          v-if="search.length"
          :get="{ results: 'articles/type', attrs: { search } }"
        >
          <template #default="{ results }">
            <ul>
              <li
                v-for="result in results"
                :key="result.id"
                data-test="new-location-suggestion"
                @click="select(result)"
              >
                {{ result.title }}
              </li>
            </ul>
          </template>
        </fetch-data>
        <ul>
          <li
            v-for="category in categories"
            :key="category.key"
            @click="createAndSelect(category.key)"
          >
            {{ category.label }}
          </li>
        </ul>
      </template>
      <template v-else>
        <button @click="removeLink">X</button>
        <fetch-data :get="{ article: 'articles/single', attrs: { id } }">
          <template #default="{ article }">
            <div class="article-wrap">
              <Article :article="article" simple @titleUpdated="syncTitle" />
            </div>
          </template>
        </fetch-data>
      </template>
    </template>
  </div>
</template>

<script>
import Article from '@/views/articles/Article.vue';

export default {
  components: {
    Article,
  },
  props: {
    item: { type: Object, default: () => null },
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    type() {
      if (!this.item) return '';
      return this.item.data.type;
    },
    id() {
      return this.item.data.id;
    },
    categories() {
      return this.$store.getters['categories/all'];
    },
  },
  methods: {
    select(result) {
      // eslint-disable-next-line vue/no-mutating-props
      this.item.data.id = result.id;
      if (this.type === 'article') {
        this.$emit('selectLink', result);
      }
      this.search = '';
    },
    createAndSelect(type) {
      this.$store.dispatch('articles/create', { title: '', type }).then((article) => {
        this.select(article);
      });
    },
    removeLink() {
      this.item.children.forEach((child) => {
        if (child.data.bg || child.data.type === 'title') {
          this.$nextTick(() => child.remove());
        }
      });
      delete this.item.data.id;
    },
    syncTitle(title) {
      if (this.type !== 'article') return;
      this.$emit('updateTitle', { item: this.item, title });
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.article-wrap {
  padding: 0 24px;
  flex: 1;
  overflow: scroll;
}
</style>
