<template>
  <div class="wrapper" ref="wrapper" :class="rootClass">
    <div class="radius">
      <input type="range" min="1" max="40" v-model="radius" />
    </div>
    <div class="toolbar">
      <button
        :class="{'toolbar-btn': true, 'toolbar-btn--active': activeTool === tool.name}"
        v-for="tool in tools"
        :key="tool.name"
        @click="enableTool(tool.name)"
      >
        <img :src="require('@/assets/' + tool.icon + '.svg')" />
      </button>
      <button @click="exportData">S</button>
    </div>
    <button v-if="0" @click="exportData">Export</button>
    <div class="canvaswrap" ref="canvaswrap">
      <div class="grid" :style="gridStyles" />
      <canvas class="canvas" ref="canvas" />
    </div>

    <Panel
      class="panel"
      :item="selectedItem"
      @selectLink="selectSearchItem"
      @updateTitle="updateTitle"
    />

    <div v-if="midpoint" class="midpoint" :style="{
      top: midpoint[1] + 'px',
      left: midpoint[0] + 'px',
      }" />
  </div>
</template>

<script>
import paper from 'paper';

import movement from './movement';
import toolSelector from './tool-selector';
import toolLines from './tool-lines';
import toolLandmarks from './tool-landmarks';
import toolTerrain from './tool-terrain';
import toolPlaces from './tool-places';
import toolRegions from './tool-regions';

import Panel from './Panel.vue';

export default {
  props: {
    article: { type: Object, required: true },
  },
  mixins: [
    toolSelector, toolLines, toolLandmarks, toolTerrain, toolPlaces, movement, toolRegions,
  ],
  components: {
    Panel,
  },
  data() {
    return {
      midpoint: false,
      bottomMap: {},
      radius: 20,
      activeTool: 'terrain',
      layers: {
        terrain: null,
        regions: null,
        lines: null,
        mountains: null,
        places: null,
        util: null,
      },
      dragging: false,
      strokeColor: '',
      waterFill: '',
      terrainFill: '',
      textColor: '',
      tools: [
        { name: 'selector', icon: 'pointer' },
        { name: 'terrain', icon: 'land' },
        { name: 'terrainpen', icon: 'land' },
        { name: 'water', icon: 'sea' },
        { name: 'waterpen', icon: 'sea' },
        { name: 'mountains', icon: 'mountain' },
        { name: 'trees', icon: 'trees' },
        { name: 'rivers', icon: 'river' },
        { name: 'roads', icon: 'road' },
        { name: 'places', icon: 'mountain' },
        { name: 'regions', icon: 'road' },
        { name: 'text', icon: 'river' },
      ],
      toolListeners: {},
      keyListeners: [],
      zoom: 1,
      viewPos: { x: 0, y: 0 },
    };
  },
  watch: {
    theme() {
      // Next tick because the class needs to be applied for the change to take affect
      this.$nextTick(() => this.loadThemeColors());
    },
  },
  computed: {
    theme() {
      return this.$store.getters['ui/theme'];
    },
    rootClass() {
      return {
        dragging: this.dragging,
      };
    },
    gridStyles() {
      const { x, y } = this.viewPos;
      return {
        backgroundSize: `${150 * this.zoom}px ${150 * this.zoom}px`,
        backgroundPosition: `${-x * this.zoom}px ${-y * this.zoom}px`,
      };
    },
  },
  methods: {
    onTool(tool, callback) {
      this.toolListeners[tool] = callback;
    },
    onKeyCommand(test, command) {
      this.keyListeners.push({
        test,
        command,
      });
    },
    loadThemeColors() {
      this.strokeColor = getComputedStyle(this.$el)
        .getPropertyValue('--color-map-outline').trim();
      this.waterFill = getComputedStyle(this.$el)
        .getPropertyValue('--color-map-water').trim();
      this.terrainFill = getComputedStyle(this.$el)
        .getPropertyValue('--color-map-terrain').trim();
      this.textColor = getComputedStyle(this.$el)
        .getPropertyValue('--color-text').trim();
    },
    disableAllTools() {
      console.log('Disabling all tools');
      this.tool.onMouseDrag = null;
      this.tool.onMouseUp = null;
      this.tool.onMouseDown = null;
      this.deselect();
    },
    enableCurrentTool() {
      this.enableTool(this.activeTool);
    },
    enableTool(tool) {
      this.tool.onMouseDrag = null;
      this.tool.onMouseUp = null;
      this.tool.onMouseDown = null;

      if (this.toolListeners[tool]) {
        console.log('Enabling tool:', tool);
        this.toolListeners[tool]();
        this.activeTool = tool;
      } else {
        console.warn('Unknown tool: ', tool);
      }
    },
    exportData() {
      this.$store.dispatch('articles/update', {
        id: this.article.id,
        body: paper.project.exportJSON(),
      });
    },
    importJson(json) {
      console.log('Loading from json');
      paper.project.clear();
      paper.project.importJSON(json);
    },
    loadLayers() {
      Object.keys(this.layers).forEach((name, index) => {
        const layer = paper.project.layers.find((l) => l.name === name);
        if (layer) {
          this.layers[name] = layer;
        } else {
          const newLayer = new paper.Layer({ insert: false, name });
          paper.project.insertLayer(index, newLayer);
          this.layers[name] = newLayer;
        }
      });
    },
    activateLayer(layer) {
      this.layers[layer].activate();
    },
  },
  mounted() {
    this.loadThemeColors();
    paper.install(window);
    paper.setup(this.$refs.canvas);
    this.tool = new paper.Tool();

    if (this.article.body) {
      this.importJson(this.article.body);
    }
    this.loadLayers();

    this.enableTool('selector');

    this.tool.on('keyup', (event) => {
      this.keyListeners.forEach((listener) => {
        if (listener.test(event)) {
          listener.command(event);
        }
      });
    });
  },
  unmounted() {
    paper.clear();
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative&display=swap');
.wrapper {
  margin: -72px -64px;
  position: relative;
  height: calc(100% + 144px);
  display: flex;

  &.dragging {
    cursor: grab;
  }
}

.canvaswrap {
  width: 100%;
  height: 100%;
  position: relative;
  /* position: absolute;
  top: 50%;
  left: 50%; */
}

.grid {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
  background-image:
    linear-gradient(to right, rgba(0,0,0,0.06) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(0,0,0,0.06) 1px, transparent 1px);
}

.canvas {
  background: var(--color-map-water);
  width: 100%;
  height: 100%;
}

.toolbar {
  width: 40px;
  position: absolute;
  z-index: 100;
  background: var(--color-menu-background);
  top: 24px;
  left: 24px;
  box-shadow: 0px 2px 4px rgba(30, 44, 57, 0.2);
  border-radius: 6px;
}

.toolbar-btn {
  width: 40px;
  height: 40px;
  border: 0;
  background: none;
  outline: none;
  color: var(--color-map-outline) !important;

  &--active {
    background: var(--color-menu-background-highlight);
  }
}

.radius {
  position: absolute;
  transform-origin: center;
  transform: translateX(-50%) rotate(-90deg);
  top: 400px;
  left: 24px;
  z-index: 100;
  height: 40px;
  display: flex;
  align-items: center;
}

.midpoint {
  position: absolute;
  background: rgba(blue, 0.2);
  border-radius: 20px;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%)
}

.panel {
  min-width: 320px;
}
</style>
