<template>
  <div data-test="section-text">
    <title-field
      :title="section.title"
      @update="updateTitle"
      placeholder="Title"
      small
      data-test="section-text-title"
    />
    <content-editor
      data-test="article-section"
      :content="section.content"
      @update="updateSection"
    />
  </div>
</template>

<script>
import ContentEditor from '@/components/ContentEditor.vue';
import TitleField from '@/components/TitleField.vue';

export default {
  props: {
    section: { type: Object, required: true },
    article: { type: Object, required: true },
  },
  components: {
    ContentEditor,
    TitleField,
  },
  methods: {
    updateSection(content) {
      this.$store.dispatch('articles/update', {
        id: this.article.id,
        d_section: {
          id: this.section.id,
          attrs: { content },
        },
      });
    },
    updateTitle(title) {
      this.$store.dispatch('articles/update', {
        id: this.article.id,
        d_section: {
          id: this.section.id,
          attrs: { title },
        },
      });
    },
  },
};
</script>
