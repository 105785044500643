import paper from 'paper';

const toolSelector = {
  data() {
    return {
      outline: null,
      moveTriggers: [],
      selectedItem: null,
      selector: {
        startPosition: null,
        dX: 0,
        dY: 0,
        wasDragged: false,
      },
    };
  },
  mounted() {
    this.onTool('selector', this.enableSelector);
  },
  methods: {
    onItemMove(cb) {
      this.moveTriggers.push(cb);
    },
    enableSelector() {
      this.tool.minDistance = 0;

      this.tool.onMouseDown = this.selectStart;
      this.tool.onMouseDrag = this.selectMove;
      this.tool.onMouseUp = this.selectMoveEnd;

      this.onKeyCommand(
        (event) => event.key === 'backspace' && this.selectedItem,
        this.selectDelete,
      );
    },
    select(item, event) {
      this.deselect();
      this.selectedItem = item;
      this.updateSelectionOutline();
      // this.selectedItem.selected = true;
      if (this.activeTool !== 'selector') {
        this.enableTool('selector');
      }

      if (event) {
        this.selector.startPosition = item.position;
        this.selector.dX = item.position.x - event.point.x;
        this.selector.dY = item.position.y - event.point.y;
        this.selector.wasDragged = false;
      }
    },
    updateSelectionOutline() {
      if (!this.selectedItem) return;
      const {
        x, y, width, height,
      } = this.selectedItem.strokeBounds;
      if (this.outline) {
        this.outline.remove();
      }
      this.outline = new paper.Path.Rectangle(
        {
          point: [x, y],
          size: [width, height],
          strokeColor: 'blue',
          insert: false,
        },
      );
      this.layers.util.addChild(this.outline);
    },
    deselect() {
      if (this.outline) {
        this.outline.remove();
        this.outline = null;
      }
      this.selectedItem = null;
    },
    selectStart(event) {
      if (this.selectedItem) {
        this.deselect();
      }
      if (event.item && event.item.layer.id !== this.layers.terrain.id) {
        if (event.item.data.type === 'article' && event.event.metaKey) {
          const ht = event.item.hitTestAll(event.point);
          this.select(ht[0].item.parent, event);
        } else {
          console.log('Selecting', event.item);
          this.select(event.item, event);
        }
      }
    },
    selectMove(event) {
      if (this.selectedItem) {
        this.selector.wasDragged = true;
        this.selectedItem.position = new paper.Point(
          event.point.x + this.selector.dX,
          event.point.y + this.selector.dY,
        );
        this.updateSelectionOutline();
      }
    },
    selectMoveEnd() {
      if (this.selectedItem && this.selector.wasDragged) {
        this.moveTriggers.forEach((cb) => {
          cb(this.selectedItem);
        });

        this.historyAdd(
          this.selectMoveUndo(this.selectedItem, this.selector.startPosition),
          this.selectMoveUndo(this.selectedItem, this.selectedItem.position),
        );

        this.deselect();
      }
    },
    selectMoveUndo(item, pos) {
      return () => {
        // eslint-disable-next-line no-param-reassign
        item.position = pos;
        this.updateSelectionOutline();
        this.moveTriggers.forEach((cb) => {
          cb(item);
        });
      };
    },
    selectDelete() {
      const command = ((item) => () => {
        if (item.layer.id === this.layers.mountains.id) {
          delete this.bottomMap[item.id];
        }
        item.remove();
      })(this.selectedItem);

      this.addToHistory({
        undo: ((item, layer, bottomMapVal) => () => {
          // Only do reordering on the mountain layer TODO Move to landmarks somehow
          if (layer.id === this.layers.mountains.id) {
            this.bottomMap[item.id] = bottomMapVal;
            layer.insertChild(0, item);
            this.reorderAfterMove(item);
          } else {
            layer.addChild(item);
          }
        })(this.selectedItem, this.selectedItem.layer, this.bottomMap[this.selectedItem.id]),
        redo: command,
      });

      command();
      this.deselect();
    },
  },

};
export default toolSelector;
