<template>
  <div data-test="relations">
    <fetch-data
      :get="{ relations: 'relations/with', attrs: { id: article.id } }"
    >
      <template #default="{ relations }">
        <relation-list :relations="relations" :id="article.id" />
      </template>
    </fetch-data>
  </div>
</template>

<script>
import RelationList from './RelationList.vue';

export default {
  components: { RelationList },
  props: {
    article: { type: Object, required: true },
  },
};
</script>

<style lang="scss" scoped>
.headline {
  font-size: 16px;
  font-weight: 600;
}
</style>
