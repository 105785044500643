<template>
  <textarea
    :class="{title: true, small}"
    ref="title"
    :value="title"
    @input="e => $emit('update', e.target.value)"
    :placeholder="placeholder"
  />
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    small: { type: Boolean, default: false },
    placeholder: { type: String, default: 'Write something' },
  },
  watch: {
    title() {
      this.resize();
    },
  },
  mounted() {
    this.resize();
  },
  methods: {
    resize() {
      const { title } = this.$refs;
      title.style.height = '0px';
      title.style.height = `${title.scrollHeight}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: var(--color-text);
  @include text-headline1;
  padding: 0;
  padding-top: 0;
  border: 0;
  width: 100%;
  outline: none;
  height: 40px;
  resize: none;
  background: transparent;

  &::placeholder {
    color: var(--color-input-placeholder-text);
  }

  &.small {
    font-size: 24px;
    line-height: 32px;
    height: 32px;
    font-weight: 400;
  }
}
</style>
