import paper from 'paper';

const toolPlaces = {
  mounted() {
    this.onTool('places', this.enablePlaceTool);
    this.onTool('text', this.enableTextTool);
  },
  methods: {
    createPointText(str, style) {
      const text = new paper.PointText({ insert: false });
      text.content = str;
      if (style) {
        if (style.font) text.font = style.font;
        if (style.fontFamily) text.fontFamily = style.fontFamily;
        if (style.fontSize) text.fontSize = style.fontSize;
        if (style.fontWieght) text.fontWeight = style.fontWeight;
      }
      return text;
    },
    mapToPath(str, path, style) {
      if (str && str.length > 0 && path) {
        // create PointText object for each glyph
        const glyphTexts = [];
        for (let i = 0; i < str.length; i += 1) {
          glyphTexts[i] = this.createPointText(str.substring(i, i + 1), style);
          glyphTexts[i].justification = 'center';
        }
        // for each glyph find center xOffset
        const xOffsets = [0];
        for (let i = 1; i < str.length; i += 1) {
          const pairText = this.createPointText(str.substring(i - 1, i + 1), style);
          pairText.remove();
          xOffsets[i] = xOffsets[i - 1] + pairText.bounds.width + this.radius / 3.2
                  - glyphTexts[i - 1].bounds.width / 2 - glyphTexts[i].bounds.width / 2;
        }
        // set point for each glyph and rotate glyph aorund the point
        for (let i = 0; i < str.length; i += 1) {
          let centerOffs = xOffsets[i];
          if (path.length < centerOffs) {
            if (path.closed) {
              centerOffs %= path.length;
            } else {
              centerOffs = undefined;
            }
          }
          if (centerOffs === undefined) {
            glyphTexts[i].remove();
          } else {
            const pathPoint = path.getPointAt(centerOffs);
            glyphTexts[i].point = pathPoint;
            const tan = path.getTangentAt(centerOffs);
            glyphTexts[i].rotate(tan.angle, pathPoint);
          }
        }
        return glyphTexts;
      }
      return null;
    },
    enableTextTool() {
      const { places } = this.layers;
      places.activate();
      let path;
      this.tool.minDistance = 0;
      this.tool.onMouseDown = () => {
        path = new paper.Path({ data: { type: 'region' } });
        path.strokeColor = this.strokeColor;
      };

      this.tool.onMouseDrag = (event) => {
        path.add(event.point);
      };

      this.tool.onMouseUp = () => {
        path.simplify(20);
        const group = new paper.Group({
          data: {
            type: 'article',
            text: true,
          },
        });
        path.remove();
        group.addChild(path);

        this.mapText('Choose something', group);
      };
    },
    mapText(text, textShape) {
      const result = this.mapToPath(text.toUpperCase(), textShape.firstChild, {
        fontSize: this.radius,
        fontFamily: 'Cinzel Decorative',
      });
      if (result) {
        // eslint-disable-next-line no-param-reassign
        textShape.firstChild.strokeColor = null;
        result.forEach((glyph) => {
          textShape.addChild(this.generateStrokePath(glyph, Math.max(this.radius / 4, 6)));
        });
        result.forEach((glyph) => {
          textShape.addChild(glyph);
        });
        // eslint-disable-next-line no-param-reassign

        this.select(textShape);
      }
    },
    updateText(item, title) {
      item.children.forEach((child) => {
        if (child.id !== item.firstChild.id) {
          setTimeout(() => child.remove());
        }
      });
      this.mapText(title, item);
    },
    enablePlaceTool() {
      const { places } = this.layers;
      places.activate();

      this.tool.onMouseUp = (event) => {
        const circle = new paper.Group([
          new paper.Path.Circle({
            fillColor: this.textColor,
            center: event.point,
            radius: 6,
          }),
        ]);
        circle.data = {
          type: 'article',
        };
        this.select(circle);
      };
    },
    updateTitle({ item, title }) {
      if (item.data.text) {
        this.updateText(item, title);
      } else {
        item.lastChild.children.forEach((child) => {
          // eslint-disable-next-line no-param-reassign
          child.content = title;
        });
      }
    },
    generateStrokePath(text, width = 3) {
      const stroke = text.clone({ insert: false });
      stroke.strokeColor = this.terrainFill;
      stroke.strokeWidth = width;
      stroke.strokeJoin = 'round';
      return stroke;
    },
    selectSearchItem(searchItem) {
      if (this.selectedItem.data.text) {
        this.updateText(this.selectedItem, searchItem.title);
        return;
      }
      console.log(searchItem, this.selectedItem);
      const { x, y } = this.selectedItem.children[0].position;

      const label = new paper.PointText({
        point: [x + 15, y + 5],
        content: searchItem.title,
        fontFamily: 'Grenze Gotisch',
        fillColor: this.textColor,
        fontWeight: '400',
        fontSize: 17,
        insert: false,
      });

      const text = new paper.Group([
        this.generateStrokePath(label), label,
      ]);
      text.data.type = 'title';
      this.selectedItem.addChild(text);
      this.selectedItem.data = {
        type: 'article',
        id: searchItem.id,
      };
    },
  },

};
export default toolPlaces;
