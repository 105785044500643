<template>
  <div :class="rootClass" :style="styles">
    <span class="article-icon__inner material-icons-outlined">{{ icon }}</span>
  </div>
</template>

<script>
import seedrandom from 'seedrandom';

export default {
  props: {
    article: { type: Object, required: true },
    small: { type: Boolean, default: false },
  },
  computed: {
    rootClass() {
      return {
        'article-icon': true,
        'article-icon--small': this.small,
      };
    },
    categories() {
      return this.$store.getters['categories/all'];
    },
    icon() {
      const category = this.categories.find((c) => c.key === this.article.type);
      return category ? category.icon : '';
    },
    seed() {
      const f = seedrandom(this.article.id);
      return f();
    },
    styles() {
      const h = this.seed * 360;
      return {
        backgroundColor: `hsla(${h}, 74%, 83%, 1)`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.article-icon {
  flex-shrink: 0;
  width: 70px;
  height: 40px;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &__inner {
    color: #000;
    opacity: 0.5;

    .article-icon--small & {
      font-size: 18px;
    }
  }

  &--small {
    width: 24px;
    height: 24px;
  }
}
</style>
