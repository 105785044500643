<template>
  <div :class="rootClass">
    <div class="trigger" @click="toggleDropdown">
      <slot name="trigger" />
    </div>
    <div class="dropdown">
      <slot :close="toggleDropdown" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  computed: {
    rootClass() {
      return {
        'dropdown-button': true,
        'dropdown-button--open': this.open,
      };
    },
  },
  methods: {
    toggleDropdown() {
      this.open = !this.open;
      document[`${this.open ? 'add' : 'remove'}EventListener`]('click', this.checkClickOutside);
    },
    checkClickOutside({ target }) {
      if (this.$el !== target && !this.$el.contains(target)) {
        this.toggleDropdown();
      }
    },
  },
  unmounted() {
    document.removeEventListener('click', this.checkClickOutside);
  },
};
</script>

<style lang="scss" scoped>
$open: null;

.dropdown-button {
  &--open {
    $open: & !global;
  }
}

.dropdown {
  position: absolute;
  background: var(--color-card-background);
  z-index: $layer-dropdown;
  display: none;
  border-radius: 4px;

  #{$open} & {
    display: block;
  }
}
</style>
