<template>
  <node-view-wrapper data-test="nested-cutout" class="nested-cutout">
    <fetch-data :get="{ article: 'articles/single', attrs: { id } }">
      <template #default="{article}">
        <span>Cutout of {{ article.title }}</span>
      </template>
    </fetch-data>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';

export default {
  components: {
    NodeViewWrapper,
  },

  props: nodeViewProps,

  computed: {
    id() {
      return this.node.attrs.id;
    },
  },
};
</script>

<style lang="scss">
.nested-cutout {
  padding: 0px 4px;
  margin: 8px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  line-height: 22px;
}
</style>
