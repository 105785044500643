<template>
  <div data-test="article-section">
    <fetch-data
      combine
      :get="{
        items: 'articles/type',
        attrs: { ...filter },
      }"
    >
      <template #default="{ items, _pushId, _loading }">
        <SectionListHeader
          :article="article"
          :filter="filter"
          :section="section"
          @addItem="addItem(_pushId)"
        />

        <div v-if="!_loading">
          <div
            data-test="article-type-item"
            class="item"
            v-for="item in items.filter((i) => i.id !== article.id)"
            :key="item.id"
          >
            <div class="item__header">
              <ArticleIcon :article="item" class="item__icon" />
              <input
                :value="item.title"
                @input="(e) => updateRelationTitle(item.id, e.target.value)"
                placeholder="Title"
                class="item__title"
                data-test="article-type-title"
              />

              <router-link
                class="link"
                :to="{ name: 'article', params: { id: item.id } }"
              >open
              </router-link>
            </div>
            <simple-editor
              data-test="item-editor"
              :content="item.body"
              @update="(val) => saveRelation(item.id, 'body', val)"
            />
          </div>
        </div>
      </template>
    </fetch-data>
  </div>
</template>

<script>
import SimpleEditor from '@/components/SimpleEditor.vue';
import ArticleIcon from '@/components/ArticleIcon.vue';
import SectionListHeader from './SectionListHeader.vue';

export default {
  props: {
    section: { type: Object, required: true },
    article: { type: Object, required: true },
  },
  components: {
    ArticleIcon,
    SimpleEditor,
    SectionListHeader,
  },
  computed: {
    filter() {
      const filter = {};
      const {
        type, children, descendants, relation,
      } = this.section.filter || {};
      if (type) filter.type = type;
      if (children) {
        filter.parent = this.article.id;
      }
      if (descendants) {
        filter.ancestor = this.article.id;
      }
      if (relation) {
        filter.relation = relation;
        filter.relationId = this.article.id;
      }
      return filter;
    },
  },
  methods: {
    updateSection(content) {
      this.$store.dispatch('articles/update', {
        id: this.article.id,
        d_section: {
          id: this.section.id,
          attrs: { content },
        },
      });
    },
    saveRelation(id, key, value) {
      this.$store.dispatch('articles/update', {
        id,
        [key]: value,
      });
    },
    updateRelationTitle(id, title) {
      this.$store.dispatch('articles/update', {
        id,
        title,
      });
    },
    async addItem(callback) {
      const item = {
        title: '',
      };
      const {
        type, parent, ancestor, relation,
      } = this.filter;
      if (type) {
        item.type = type;
      }
      if (parent) item.parent = parent;
      else if (ancestor) item.parent = ancestor;
      else if (relation) {
        item.relation = relation;
        item.relationId = this.article.id;
      }
      const newArticle = await this.$store.dispatch('articles/create', item);
      callback(newArticle.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  padding: 0;
  border: 0;
  width: 100%;
  outline: none;
}
.item {
  background: var(--color-card-background);;
  padding: 16px;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 16px;
}
.item__icon {
  margin-right: 8px;
}
.item__title {
  @include text-cutout-title;
  padding: 0;
  border: 0;
  width: 100%;
  outline: none;
  background: none;
  color: var(--color-text);;
}
.item__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.header {
  display: flex;
}

.link {
  color: var(--color-text);;
  text-decoration: none;
  line-height: 32px;

  &:hover {
    text-decoration: underline;
  }
}
</style>
