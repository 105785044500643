import paper from 'paper';
import brushes from './brushes';

const toolLandmarks = {
  mounted() {
    this.onTool('mountains', this.enableMountainTool);
    this.onTool('trees', () => {
      this.enableMountainTool(true);
    });
    this.onItemMove((item) => {
      if (item.layer.id === this.layers.mountains.id) {
        this.reorderAfterMove(item);
      }
    });

    this.$nextTick(() => {
      Object.keys(this.bottomMap).forEach((key) => {
        delete this.bottomMap[key];
      });
      this.layers.mountains.children.forEach((child) => {
        this.bottomMap[child.id] = { itemBottom: child.strokeBounds.bottom, item: child };
      });
      console.log('Bottommap', this.bottomMap);
    });
  },
  methods: {
    enableMountainTool(trees = false) {
      const { mountains } = this.layers;
      mountains.activate();

      let addedElements = [];
      // let redoStack = [];

      this.tool.minDistance = trees ? 10 : this.radius;
      const addContent = (event) => {
        const brush = trees ? this.getTreeBrush() : this.getMountainBrush();
        brush.position = event.point;
        addedElements.push(brush);
        this.reorderAfterMove(brush);
      };

      this.tool.onMouseDown = () => {
        addedElements = [];
      };

      this.tool.onMouseDrag = (event) => {
        addContent(event);
      };

      this.tool.onMouseUp = (event) => {
        if (addedElements.length === 0) {
          addContent(event);
        }
        this.addToHistory({
          undo: ((elements) => () => {
            elements.forEach((element) => element.remove());
          })(addedElements),
          redo: ((elements) => () => {
            elements.forEach((element) => {
              this.layers.mountains.insertChild(0, element);
              this.reorderAfterMove(element); // Slightly inneficient but okay for a redo
            });
          })(addedElements),
        });
      };
    },
    getMountainBrush() {
      const mt = brushes.MTS[Math.floor(Math.random() * brushes.MTS.length)];
      const brush = new paper.Group({
        children: [new paper.Path(mt.fill), new paper.Path(mt.stroke)],
      });
      brush.children[1].strokeColor = this.strokeColor;
      brush.children[0].fillColor = this.terrainFill;
      brush.scaling = [
        this.radius / 20 + Math.random() / 3,
        this.radius / 20,
      ];
      return brush;
    },
    getTreeBrush() {
      const tree = brushes.TREES[Math.floor(Math.random() * brushes.TREES.length)];
      const brush = new paper.Path(tree);
      brush.strokeColor = this.strokeColor;
      brush.fillColor = this.terrainFill;
      brush.scaling = 0.8;
      return brush;
    },
    reorderAfterMove(itemToReorder) {
      const { mountains } = this.layers;
      const compoundBtm = itemToReorder.strokeBounds.bottom;
      let matchedItem;
      let diff = Infinity;
      mountains.children.forEach((child) => {
        if (child.id === itemToReorder.id) return;
        const { itemBottom, item } = this.bottomMap[child.id];
        if (itemBottom <= compoundBtm && compoundBtm - itemBottom <= diff) {
          matchedItem = item;
          diff = compoundBtm - itemBottom;
        }
      });
      if (matchedItem) {
        itemToReorder.insertAbove(matchedItem);
      } else {
        itemToReorder.sendToBack();
      }
      this.bottomMap[itemToReorder.id] = {
        itemBottom: compoundBtm,
        item: itemToReorder,
      };
    },
  },
};
export default toolLandmarks;
