<template>
  <BubbleMenu :editor="editor">
    B I U
    <select @change="e => createPage(e.target.value)" ref="selector">
      <option value="">Turn into page</option>
      <option
        v-for="category in categories"
        :key="category.key"
        :value="category.key"
      >
        {{ category.label }}
      </option>
    </select>
  </BubbleMenu>
</template>

<script>
import { BubbleMenu } from '@tiptap/vue-3';

export default {
  props: {
    editor: { type: Object, required: true },
  },
  components: {
    BubbleMenu,
  },
  computed: {
    categories() {
      return this.$store.getters['categories/all'];
    },
  },
  methods: {
    createPage(type) {
      if (!type) return;
      this.$refs.selector.value = '';
      const { state } = this.editor;
      const { selection } = state;
      const { from, to } = selection;
      const title = state.doc.textBetween(from, to, ' ');

      this.$store.dispatch('articles/create', { title, type }).then(({ id }) => {
        this.editor.commands.insertContent([
          {
            type: 'mention',
            attrs: { id },
          },
        ]);
      });
    },
  },
};
</script>
